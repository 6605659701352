/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: 'aa3bbe2bccd05b1460b4fc5ec72ebb97944257d1' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('') {
  const key = 'undefined' ? '@undefined' : '';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: 'aa3bbe2bccd05b1460b4fc5ec72ebb97944257d1' };
}

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { init } from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import { getDefaultConfig } from './SentryConfig';

// TODO: Ensure configuration works for bost old and new build system
init({
  integrations: [
    new BrowserTracing({
      tracingOrigins: [/consumer-client-api(\.staging\.internal)?\.capsule\.com/],
      beforeNavigate: context => {
        if (context.name.includes('/[[...') || context.name.includes('/[...')) {
          return {
            ...context,
            name: window.location.pathname,
          };
        } else {
          return context;
        }
      },
    }),
  ],
  ...getDefaultConfig(process.env.NEXT_PUBLIC_CAPSULE_ENV),
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
